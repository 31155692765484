import { Controller } from "@hotwired/stimulus";
import { createRoot, Root } from "react-dom/client";
import { formatMediaHolders } from "../components/utils";
import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import { formatMedia } from "../components/utils";
import fr from "../../../config/locales/js-lang/fr.json";
import ContentMediaUploader from "../components/ContentMediaUploader";


// Ce controller sert à utiliser l'uploader react hors des formulaires React
// TODO: à un moment il faudra refacto

export default class extends Controller {

  get props() {
    return JSON.parse(this.data.get("props"))
  }

  disconnect() {
    this.root?.unmount();
  }

  connect() {

    this.root = createRoot(this.element);
    this.root.render(
      <CloudinaryStimulusWrapper baseMedia={this.props.media} fieldName={this.props.field_name} />
    );
  }

}



const CloudinaryStimulusWrapper = ({ baseMedia, fieldName }) => {
  console.log("medi")
  console.log(baseMedia)
  const [media, setMedia] = useState(baseMedia)
  const formattedMedia = formatMediaHolders(media)
  return <div>
    <div id="hidden-fields">
      {formattedMedia.map((med, i) => {
        console.log(med)
        return <>
          <input type="hidden" name={`${fieldName}[${i}][position]`} value={med.position} />
          {!med.id && <input type="hidden" name={`${fieldName}[${i}][medium]`} value={med.medium} />}
          {med.id && <input type="hidden" name={`${fieldName}[${i}][id]`} value={med.id} />}
          {med._destroy && <input type="hidden" name={`${fieldName}[${i}][_destroy]`} value={1} />}
        </>
      }
      )}
    </div>
    <IntlProvider
      locale="fr"
      messages={fr}
    >
      <ContentMediaUploader media={media} setMedia={setMedia} />
    </IntlProvider>
  </div>
}
