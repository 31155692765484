import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Company } from "../types";
import ColorPicker from "./ColorPicker";
import FormField from "./FormField";
import FormLayout from "./FormLayout";
import Input from "./Input";
import ProfilePictureEditor from "./ProfilePictureEditor";
import { getCSRFToken } from "./utils";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "./Select";

function CompanyForm({ company: { profile_picture, ...company } }: { company: Company }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
  } = useForm({
    defaultValues: {
      company: {
        ...company,
        password: null,
        password_confirmation: null,
        sendinblue_sender_email: company.sendinblue_sender_email?.split("@")[0],
      },
    },
  });

  const defaultRequiredMessage = "Ce champ ne peut être vide.";

  const onSubmit = async (data: any) => {
    if (profilePicture) {
      // On renvoie profilePicture sans la picture (mais avec id si on en a reçu une)
      const { serialized_file, ...rest } = profilePicture;
      data.company.profile_picture_attributes = rest;
      if ("signature" in serialized_file) {
        // Si nouvelle image, on renvoie la nouvelle url sous la forme identifier#signature
        data.company.profile_picture_attributes.picture =
          serialized_file.identifier + "#" + serialized_file.signature;
      }
    }
    data.company.sendinblue_sender_email += "@apinews.io";

    const authenticity_token = getCSRFToken();
    const response = await fetch(`/companies/${company.id}`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": authenticity_token,
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      window.Turbo?.reload()
    }
  };

  const genderOptions = [
    { label: "Je suis une entreprise", value: "Je suis une entreprise" },
    { label: "Je suis une indépendante", value: "Je suis une indépendante" },
    { label: "Je suis un indépendant", value: "Je suis un indépendant" },
    { label: "Je suis un professionnel de santé", value: "Je suis un professionnel de santé" },
    { label: "Je suis une professionnelle de santé", value: "Je suis une professionnelle de santé" },
    { label: "Je suis un cabinet d'avocat", value: "Je suis un cabinet d'avocat" },
  ];


  const [profilePicture, setProfilePicture] = useState(profile_picture);

  const intl = useIntl()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormLayout
        title={intl.formatMessage({ id: "company_form.my_company", defaultMessage: "Mon entreprise" })}
        leftSideElements={
          <>
            <ProfilePictureEditor
              profilePicture={profilePicture}
              setProfilePicture={setProfilePicture}
            />
          </>
        }
        description={intl.formatMessage({ id: "company_form.edit", defaultMessage: "Éditez les informations vous concernant" })}
      >


        <Input
          title={intl.formatMessage({ id: "company_form.company", defaultMessage: "Enseigne" })}
          hint={intl.formatMessage({ id: "company_form.company_name", defaultMessage: "Le nom à travers lequel vous apparaîtrez dans les messages envoyés" })}

          {...register("company.displayed_name", { required: defaultRequiredMessage })}
          error={errors?.company?.company?.message}
        />

        <Input
          title={intl.formatMessage({ id: "company_form.company", defaultMessage: "Société" })}
          hint={intl.formatMessage({ id: "company_form.company_name", defaultMessage: "Le nom de votre société, ou marque." })}

          {...register("company.company", { required: defaultRequiredMessage })}
          error={errors?.company?.company?.message}
        />

        <Input
          title={intl.formatMessage({ id: "company_form.email", defaultMessage: "Email" })}
          {...register("company.email", { required: defaultRequiredMessage })}
          error={errors?.company?.email?.message}
        />
        <Input
          title={intl.formatMessage({ id: "company_form.phone", defaultMessage: "Téléphone" })}
          {...register("company.phone", { required: defaultRequiredMessage })}
          error={errors?.company?.phone?.message}
        />

        <Input
          title={intl.formatMessage({ id: "company_form.sendinblue_sender_name", defaultMessage: "Nom d'expéditeur" })}
          {...register("company.sendinblue_sender_name", { required: defaultRequiredMessage })}
          hint={intl.formatMessage({ id: "company_form.sendinblue_sender_name", defaultMessage: "Le nom apparaissant sur les newsletters que vous envoyez" })}
          error={errors?.company?.sendinblue_sender_name?.message}
        />

        <Input
          title={intl.formatMessage({ id: "company_form.sendinblue_sender_email", defaultMessage: "Adresse d'expéditeur" })}
          hint={intl.formatMessage({ id: "company_form.sendinblue_sender_name", defaultMessage: "L'email apparaissant sur les newsletters que vous envoyez" })}
          suffix={"@apinews.io"}
          {...register("company.sendinblue_sender_email", { required: defaultRequiredMessage })}
          error={errors?.company?.sendinblue_sender_email?.message}
        />

        <FormField
          title={intl.formatMessage({ id: "company_form.gender", defaultMessage: "Genre" })}
          hint={intl.formatMessage({ id: "company_form.gender_hint", defaultMessage: "Personnalisez votre message au féminin ou masculin" })}
        >
          <Controller
            name="company.gender"
            control={control}
            render={({ field }) => (
              <Select
                onChange={(option) => field.onChange(option)}
                list={genderOptions}
                value={field.value}
              />
            )}
          />
        </FormField>
        <FormField
          title={intl.formatMessage({ id: "company_form.color", defaultMessage: "Couleur" })}
          hint={intl.formatMessage({ id: "company_form.color_hint", defaultMessage: "Utilisée pour décorer vos contenus" })}
        >
          <Controller
            name="company.primary_color"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <ColorPicker
                name={name}
                onChange={onChange}
                value={value}
                error={errors?.company?.primary_color?.message}
              />
            )}
          />
        </FormField>
        <Input
          title={intl.formatMessage({ id: "company_form.hashtags", defaultMessage: "Hashtags" })}
          hint={intl.formatMessage({ id: "company_form.hashtags_hint", defaultMessage: "La liste de vos hashtags commençant par '#'" })}
          {...register("company.hashtag", {
            required: false,
            pattern: /^[#]\w+/,
          })}
          error={errors?.company?.hashtag?.message}
        />
        <div className="flex justify-end">
          <button
            className="btn-brand-primary"
            type="submit"
          >
            <FormattedMessage id="company_form.save" defaultMessage="Enregistrer" />
          </button>
        </div>
      </FormLayout>
    </form>
  );
}

export default CompanyForm;
