import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use";
import { computePosition, autoPlacement, autoUpdate } from "@floating-ui/dom";

export default class extends Controller {
  static targets = ["card", "content"]
  static values = { placement: String, autoplacement: Boolean, updateOnScroll: Boolean }


  get anchor() {
    return document.getElementById("floating_menu_anchor")
  }

  disconnect() {
    this.hide()
  }

  position() {
    computePosition(this.element, this.menu, {
      placement: this.placementValue || "right", middleware: [this.autoplacementValue && autoPlacement()]
    }).then(({ x, y }) => {
      Object.assign(this.menu.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  async show(event) {
    event.preventDefault()

    const content = this.contentTarget.innerHTML;
    const fragment = document.createRange().createContextualFragment(content);
    this.anchor.appendChild(fragment);
    this.menu = this.anchor.querySelector("[data-floating-menu-target='menu']");
    this.menu.onclick = () => { setTimeout(() => this.menu.remove(), 10) }
    if (this.menu) {
      if (this.updateOnScrollValue) {
        this.cleanup = autoUpdate(this.element, this.menu, () => {
          console.log("update")
          this.position()
        })
      } else {
        this.position()
      }
      useClickOutside(this, { element: this.menu });
    }
  }

  clickOutside() {
    this.hide()
  }

  hide() {
    if (this.cleanup) {
      this.cleanup()
      this.cleanup = null
    }
    if (this.menu) {
      this.menu.remove()
    }
  }

}
