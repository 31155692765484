import { useState, useEffect } from "react";

export const useCharacterCount = (messageLength: number) => {
    const [twitterRemaining, setTwitterRemaining] = useState<number>(280);
    const [instagramRemaining, setInstagramRemaining] = useState<number>(2200);
    const [facebookRemaining, setFacebookRemaining] = useState<number>(5000);
    const [googleMyBusinessRemaining, setGoogleMyBusinessRemaining] = useState<number>(1500);
    const [linkedInRemaining, setLinkedInRemaining] = useState<number>(1300);
    const [pinterestRemaining, setPinterestRemaining] = useState<number>(500);

    useEffect(() => {
        // Update remaining characters when message changes
        const length = messageLength || 0;
        setTwitterRemaining(280 - length);
        setInstagramRemaining(2200 - length);
        setFacebookRemaining(5000 - length);
        setGoogleMyBusinessRemaining(1500 - length);
        setLinkedInRemaining(1300 - length);
        setPinterestRemaining(500 - length);
      }, [messageLength]);
      
        return {
            twitterRemaining,
            instagramRemaining,
            facebookRemaining,
            googleMyBusinessRemaining,
            linkedInRemaining,
            pinterestRemaining,
        };
}