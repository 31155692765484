import React, { SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { gql, GraphQLClient } from "graphql-request";
import ChannelAccountSelector from "./ChannelAccountSelector";
import ContentMediaUploader from "./ContentMediaUploader";
import FacebookPreview from "./FacebookPreview";
import InsertableTextInput from "./InsertableTextInput";
import Select from "./Select";
import { getCSRFToken, formatMediaHolders } from "./utils";
import {
  BoltIcon,
  PaperAirplaneIcon,
  ClockIcon,
} from "@heroicons/react/24/solid";
import { useOpenAI } from "../hook/useOpenAI";
import { useUserChoice } from "../hook/useUserChoice";
import { useCharacterCount } from "../hook/useCharacterCount";
import {
  Channel,
  ContentMedium,
  MediaHolder,
  isPersisted,
  Post,
  ChannelAccount,
  PostOnChannel,
} from "../types";
import DatePicker from "./DatePicker";
import FormField from "./FormField";
import UrlScraper from "./UrlScraper";
import FormLayout from "./FormLayout";
import ChoiceButton from "./ChoiceButton";
import CharacterCountPost from "./CharacterCountPost";
import OpenAiForm from "./OpenAiForm";
import TextArea from "./TextArea";
import Spinner from "./Spinner";
import { FormattedMessage, useIntl } from "react-intl";
import computeFocus from "../utils/computeFocus";

type Props = {
  post: Post & { post_on_channels: PostOnChannel[] };
  post_on_channels: PostOnChannel[];
  example_account: {
    displayed_name: string;
    picture_url: string;
    company: {
      email: string;
      company: string;
      phone: string;
      hashtag: string;
      website: string;
      displayed_name: string;
      client_code: string;
      last_landing_path: string;
      last_newsletter_path: string;
      last_apilink: string;
    };
  };
  channel_accounts: Array<ChannelAccount & { channel: Channel }>;
  periodicity_options: Record<string, string>;
  current_company: any;
};

export default function PostForm(props: Props) {
  const { current_company } = props;
  const minDate = new Date();
  const maxDate = new Date();
  const defaultStartingAt = new Date();
  minDate.setMinutes(minDate.getMinutes() + 5);
  maxDate.setMonth(maxDate.getMonth() + 2);
  defaultStartingAt.setHours(defaultStartingAt.getHours() + 1);
  const [submitted, setSubmitted] = useState(false);
  const { company_content, ...post } = props.post;
  const { post_on_channels } = props;
  const { body = { body_media: [] }, ...company_content_attributes } =
    company_content;
  const [prompt, setPrompt] = useState<string>("")
  const [showInputMessage, setShowInputMessage] = useState<boolean | null>(false);
  const [loading, setLoading] = useState(false)

  const [media, setMedia] = useState<MediaHolder[]>(
    body.body_media
      .filter((m: MediaHolder) => m.content_medium.serialized_file)
      .sort(
        (a: MediaHolder, b: MediaHolder) =>
          (a.position || 0) - (b.position || 0)
        // Si undefined on met la valeur max (media.length)
      ) || []
  );
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    control,
    formState: { errors, isSubmitted },
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      post: {
        ...post,
        periodicity: post.periodicity || "one_time",
        scheduled_at: post.scheduled_at ? new Date(post.scheduled_at) : null,
        channel_account_ids: post.id
          ? post_on_channels.map((p) => p.channel_account_id.toString())
          : props.channel_accounts.map((ca) => ca.id.toString()),
        company_content_attributes: { body, ...company_content_attributes },
      },
    },
  });
  const message = watch("post.company_content_attributes.body.message");
  const messageLength = message?.length;
  const {
    twitterRemaining,
    instagramRemaining,
    facebookRemaining,
    googleMyBusinessRemaining,
    linkedInRemaining,
    pinterestRemaining } = useCharacterCount(messageLength);

  const { SubmitOpenAI, disabledInput, error } = useOpenAI(
    prompt,
    loading,
    setLoading,
    media.map((m) => m.content_medium),
    setValue,
    setShowInputMessage,
    getValues,
    "post.company_content_attributes.body.message",
    `/body/openai_call`
  );

  const { handleAIButtonClick, handleManualButtonClick } = useUserChoice(setShowInputMessage);
  const [publishNow, setPublishNow] = useState(false);
  const changePublishNow = (value: boolean) => {
    setPublishNow(value);
    if (value) {
      setTimeout(() => {
        trigger("post.scheduled_at");
      }, 0);
    }
  };
  useEffect(() => {
    console.log("media", media)
    console.log("formated", formatMediaHolders(media))
  }, [media])

  // content_media.map((cm, i) => ({...cm, position: i})) pour ajouter les positions de force, mais ptet pas besoin

  const ctaOptions = [
    { label: "Commander", value: "order_now" },
    { label: "Acheter", value: "shop_now" },
    { label: "En savoir plus", value: "learn_more" },
    { label: "S'inscrire", value: "sign_up" },
    { label: "Appeler", value: "call_now" },
    { label: "Nous contacter", value: "contact_us" },
    { label: "Aucun", value: null },
  ];

  const periodicityOptions = Object.entries(props.periodicity_options)
    .map(([key, value]) => ({ label: value, value: key }))
    .filter((opt) => opt.value !== "publish_now");

  const graphQLClient = new GraphQLClient("/graphql/", {
    headers: { "X-CSRF-Token": getCSRFToken() },
  });

  const onSubmit = async (data: any) => {
    setSubmitted(true);

    const bodyMedia = formatMediaHolders(media)

    const mutation = gql`
    mutation ($post: Post${post.id ? "Update" : ""}Input!) {
      ${post.id ? "update" : "create"}Post(input: {post:$post}) {
        post {
          companyContent {
            body {
              id
              message
              contentMedia {
                medium
              }
            }
          }
        }
      }
    }`;

    const variables = {
      post: {
        companyContent: {
          id: data.post.company_content_attributes.id || undefined,
          ...(data.post.company_content_attributes.tag_ids
            ? { tagIds: data.post.company_content_attributes.tag_ids }
            : {}),
          focus: data.post.company_content_attributes.focus,
          expiresAt: data.post.company_content_attributes.expires_at,
          title: data.post.company_content_attributes.title,
          body: {
            //...("id" in body && { id: body.id }),
            message: data.post.company_content_attributes.body.message,
            bodyMediaAttributes: bodyMedia,
            linkUrl: data.post.company_content_attributes.body?.link_url,
            linkDescription:
              data.post.company_content_attributes.body?.link_description,
            linkCta: data.post.company_content_attributes.body?.link_cta,
          },
        },
        postOnChannelsAttributes: [
          // les post_on_channels qui existaient avant sont gardés ou supprimés (avec id)
          ...post_on_channels.map((p: PostOnChannel) =>
            data.post.channel_account_ids.includes(
              p.channel_account_id.toString()
            )
              ? { id: p.id }
              : { id: p.id, _destroy: true }
          ),
          // les channel_accounts qui n'existaient pas avant (pas de pocs) donnent de nouveaux post_on_channels
          ...data.post.channel_account_ids
            .filter(
              (caid: string) =>
                !post_on_channels
                  .map((p) => p.channel_account_id.toString())
                  .includes(caid)
            )
            .map((id: string) => ({
              channelAccountId: id,
            })),
        ],
        instantPublish: publishNow,
        periodicity: (!publishNow && data.post.periodicity) || "one_time",
        scheduledAt: (!publishNow && data.post.scheduled_at) || null,
        channelIds: data.post.channel_ids,
        ...(data.post.id ? { id: data.post.id } : {}),
      },
    };

    const response: any = await graphQLClient.request(mutation, variables);
    if (!response.errors) {
      window.Turbo?.visit(`/${intl.locale}/posts?display=calendar`);
    }
    setSubmitted(false);
  };

  useEffect(() => {
    console.log("errors", errors);
  });

  const channel_account_ids = watch("post.channel_account_ids");

  const link_description = watch(
    "post.company_content_attributes.body.link_description"
  );
  const previewCta = ctaOptions.find(
    (cta) => (cta.value && cta.value === watch("post.company_content_attributes.body.link_cta"))
  )?.label;

  const linkUrl = watch("post.company_content_attributes.body.link_url");
  const { focus, accept } = computeFocus(media.map(m => m.content_medium), linkUrl);

  const intl = useIntl();
  const [showIntegratedLink, setShowIntegratedLink] = useState(Boolean(linkUrl));
  const handleIntegratedLink = () => {
    // On active pas si formats incommpaptibles
    if (["images", "video"].includes(focus) && !showIntegratedLink) return
    // On désactive les champs si on quitte le lien intégré 
    if (showIntegratedLink) {
      setValue("post.company_content_attributes.body.link_url", null)
      setValue("post.company_content_attributes.body.link_description", null)
      setValue("post.company_content_attributes.body.link_cta", null)
    }
    console.log("showIntegratedLink", showIntegratedLink)
    setShowIntegratedLink(!showIntegratedLink);
  }
  useEffect(() => {
    if (["images", "video"].includes(focus)) {
      setShowIntegratedLink(false)
      setValue("post.company_content_attributes.body.link_url", null)
      setValue("post.company_content_attributes.body.link_description", null)
      setValue("post.company_content_attributes.body.link_cta", null)
    }
  }, [focus])


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormLayout
        title={intl.formatMessage({
          id: "post_form.publication",
          defaultMessage: "Publication",
        })}
        description={intl.formatMessage({
          id: "post_form.edit_content",
          defaultMessage:
            "Éditez la forme et le contenu de votre nouvelle campagne",
        })}
        leftSideElements={
          <>
            <div>
              <FacebookPreview
                focus={focus}
                teaser={message}
                cta={previewCta}
                description={link_description || ""}
                reconstructed_url={linkUrl || ""}
                exampleAccount={props.example_account}
                media={media.filter(
                  (m: MediaHolder) => !(isPersisted(m) && m.destroy)
                ).map(m => m.content_medium)}
              />
            </div>
            {!company_content_attributes.id && (
              <div>
                <UrlScraper
                  creationUrl={"/contents"}
                  redirectionCallback={(id) =>
                    `/posts/new?instantiate_from[type]=content&instantiate_from[id]=${id}`
                  }
                />
              </div>
            )}
            <div className="hidden lg:block">
              <h3 className="flex items-baseline text-sm font-semibold leading-6 text-gray-700">
                <BoltIcon className="w-3 h-3 mr-1" />
                <FormattedMessage id="post_form.tips" defaultMessage="Astuce" />
              </h3>
              <p className="text-sm text-gray-500">
                <FormattedMessage
                  id="post_form.key_words"
                  defaultMessage="Utilisez les mots clés"
                />{" "}
                {[
                  "$NOM",
                  "$ENTREPRISE",
                  "$SITE",
                  "$MAIL",
                  "$TEL",
                  "$HASHTAG",
                  "$CODE",
                  "$LANDING",
                  "$NEWSLETTER",
                  "$APILINK",
                  "$PROMOCODE",
                  "$SPONSORLINK"
                ].map((v) => (
                  <span key={v}>
                    <span className="font-medium text-gray-700">{v}</span>
                    {", "}
                  </span>
                ))}
                <FormattedMessage
                  id="post_form.custom_your_publications"
                  defaultMessage="pour personnaliser vos publications avec les valeurs correspondantes chez chaque affilié."
                />
              </p>
            </div>
          </>
        }
      >
        <FormField
          title={intl.formatMessage({
            id: "company_content_form.media",
            defaultMessage: "Média",
          })}
          hint={intl.formatMessage({
            id: "company_content_form.media_hint",
            defaultMessage:
              "Ajoutez des images ou une vidéo à votre contenu. Le lien integré ne permet pas l'ajout de vidéo.",
          })}
        >
          <ContentMediaUploader
            inputProps={{
              multiple: true,
              accept,
            }}
            disabled={["video", "link"].includes(focus) && media.filter(m => !m.destroy).length > 0}
            innerInfos={
              focus === "none" ? (
                <FormattedMessage
                  id="company_content_form.file_format_any"
                  defaultMessage="Insérez ici vos vidéos ou images"
                />
              ) : focus === "video" ? (
                <FormattedMessage
                  id="company_content_form.file_format_video"
                  defaultMessage="MP4, WEBM"
                />
              ) : (
                <FormattedMessage
                  id="company_content_form.file_format_image"
                  defaultMessage="JPG, PNG"
                />
              )
            }
            setMedia={(m) => {
              setMedia(m);
              // On trigger le focus pour revalider les changements,
              // en async sinon pas le temps de recevoir l'info du media
              setTimeout(() => {
                //trigger("post.company_content_attributes.focus");
              }, 500);
            }}
            media={media}
          //error={errors?.post?.company_content_attributes?.focus?.message}
          />
        </FormField>
        <div>
          {
            // body.message pour savoir si contenu existant (les routes varient et les ids sont nullifiées
            !body?.message && current_company.plan !== "Ambassador" && (
              <div>
                <ChoiceButton
                  showInputMessage={showInputMessage}
                  handleAiButtonClick={handleAIButtonClick}
                  handleManualButtonClick={handleManualButtonClick}
                />
              </div>
            )
          }        </div>
        {
          showInputMessage === true ?
            (
              <div>
                <OpenAiForm
                  prompt={prompt}
                  message="Entrez le sujet de votre post pour générer du texte, donner du contexte sur le message que vous souhaitez générer (limite: 30 par mois)"
                  placeholder="Exemple: 'Promouvoir un nouveau partenariat avec une entreprise'"
                  setPrompt={setPrompt}
                  loading={disabledInput}
                  error={error}
                  Submit={SubmitOpenAI}
                />
              </div>
            ) : (
              <div>
                <FormField
                  title={intl.formatMessage({
                    id: "post_form.message",
                    defaultMessage: "Message",
                  })}
                  hint={intl.formatMessage({
                    id: "company_content_form.associated_text",
                    defaultMessage: "Le texte associé à votre contenu.",
                  })}
                  error={
                    errors?.post?.company_content_attributes?.body?.message as
                    | string
                    | undefined
                  }
                >
                  <InsertableTextInput
                    {...register("post.company_content_attributes.body.message", {
                      required: intl.formatMessage({
                        id: "company_content_form.message_cant_be_blank",
                        defaultMessage: "Le message ne peut pas être vide",
                      }),
                    })}
                    onInsertionCallback={() =>
                      isSubmitted
                        ? trigger("post.company_content_attributes.body.message")
                        : null
                    }
                    rows={5}
                    inputType="textarea"
                    emojis={true}
                    setValue={setValue}
                    insertionVariables={[
                      "$NOM",
                      "$ENTREPRISE",
                      "$SITE",
                      "$MAIL",
                      "$TEL",
                      "$HASHTAG",
                      "$CODE",
                      "$LANDING",
                      "$NEWSLETTER",
                      "$APILINK",
                      "$PROMOCODE",
                      "$SPONSORLINK"
                    ]}
                    className={`form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${errors?.post?.company_content_attributes?.body?.message
                      ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
                      : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
                      }`}
                  />
                </FormField>
                <div>
                  <CharacterCountPost
                    twitterRemaining={twitterRemaining}
                    instagramRemaining={instagramRemaining}
                    facebookRemaining={facebookRemaining}
                    googleMyBusinessRemaining={googleMyBusinessRemaining}
                    linkedInRemaining={linkedInRemaining}
                    pinterestRemaining={pinterestRemaining}
                    connectedChannels={props.channel_accounts}
                  />
                </div>
                <div className="relative flex items-start mt-3">
                  <div className="flex items-center h-6">
                    <input
                      id="link"
                      aria-describedby="link-toggle"
                      name="link"
                      type="checkbox"
                      className="w-4 h-4 text-gray-600 border-gray-300 rounded focus:ring-gray-600"
                      checked={showIntegratedLink}
                      onChange={handleIntegratedLink}
                      disabled={["video", "images"].includes(focus)}
                    />
                  </div>
                  <div className={`w-full ml-3 text-sm leading-6 ${["video", "images"].includes(focus) && "opacity-50"}`}>
                    <label className="block mb-1 text-base font-semibold text-gray-700">
                      <FormattedMessage id="company_content_form.link" defaultMessage="Lien intégré" />
                    </label>

                    <div className={`w-full space-y-2 ${!showIntegratedLink && "hidden"}`}>
                      <FormField
                        disabled={["video", "images"].includes(focus)}
                        error={
                          errors?.post?.company_content_attributes?.body?.link_url
                            ?.message as string | undefined
                        }
                      >
                        <InsertableTextInput
                          {...register("post.company_content_attributes.body.link_url", {
                            validate: (value) => {
                              if (!value && showIntegratedLink) {
                                console.log("shosha", showIntegratedLink)
                                return intl.formatMessage({
                                  id: "company_content_form.link_cant_be_blank",
                                  defaultMessage:
                                    "L'url est obligatoire pour le lien intégré.",
                                });
                              } else {
                                return true;
                              }
                            },
                          })}
                          disabled={["video", "images"].includes(focus)}
                          emojis={false}
                          setValue={setValue}
                          placeholder={intl.formatMessage({ id: "company_content_form.link_url", defaultMessage: "Url du lien" })}
                          insertionVariables={["$SITE", "$CODE", "$TEL", "$SPONSORLINK"]}
                          className={`form-input flex flex-1 w-full rounded-md border-gray-300 shadow-sm ${errors?.post?.company_content_attributes?.body?.link_url
                            ? "border-red-300 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
                            : "focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
                            }`}
                        />
                      </FormField>
                      <div className="flex flex-col justify-between lg:flex-row lg:space-x-2">
                        <div className="flex-1">
                          <TextArea
                            placeholder={intl.formatMessage({ id: "company_content_form.short_description", defaultMessage: "Description du lien" })}
                            rows={1}
                            {...register(
                              "post.company_content_attributes.body.link_description"
                            )}
                            error={
                              errors?.post?.company_content_attributes?.body
                                ?.link_description
                            }
                          />
                        </div>
                        <FormField>
                          <Controller
                            name="post.company_content_attributes.body.link_cta"
                            control={control}
                            render={({ field }) => (
                              <Select
                                placeHolder={intl.formatMessage({ id: "company_content_form.action_btn", defaultMessage: "Bouton d'action" })}
                                onChange={(option) => field.onChange(option)}
                                list={ctaOptions}
                                value={field.value ? field.value : ""}
                              />
                            )}
                          />
                        </FormField>
                      </div>
                    </div>

                    <p id="link-hint" className="text-gray-500">
                      <FormattedMessage id="company_content_form.link_hint" defaultMessage="Ajouter un lien décoré à votre contenu. Incompatible avec les vidéos ou images multiples." />
                    </p>
                  </div>
                </div>
              </div>
            )
        }
      </FormLayout>
      <FormLayout
        title={intl.formatMessage({
          id: "p_form.social_networks",
          defaultMessage: "Réseaux sociaux",
        })}
        description={intl.formatMessage({
          id: "post_form.networks_hint",
          defaultMessage:
            "Choisissez les réseaux sociaux sur lesquels vous souhaitez publier votre contenu.",
        })}
      >
        <FormField
          title={intl.formatMessage({
            id: "post_form.networks",
            defaultMessage: "Réseaux",
          })}
        >
          <ChannelAccountSelector
            inputName="post.channel_account_ids"
            register={register}
            selected={channel_account_ids}
            channelAccounts={props.channel_accounts}
          />
        </FormField>
      </FormLayout>
      <FormLayout
        title={intl.formatMessage({
          id: "post_form.programmation",
          defaultMessage: "Programmation",
        })}
        description={intl.formatMessage({
          id: "post_form.schedule_your_post",
          defaultMessage:
            "Choisissez la date, l’heure et la récurrence de votre publication",
        })}
      >
        <div className="space-y-2">
          <div className="flex flex-row space-x-2">
            <button type="button" onClick={() => changePublishNow(true)}>
              <div className="relative flex items-center my-2">
                <div
                  className={`relative border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none text-sm ${publishNow
                    ? "text-brand_main border-brand_focus border ring ring-brand_lighter ring-opacity-50"
                    : "text-gray-500 border-gray-300 "
                    }`}
                >
                  <PaperAirplaneIcon className="hidden w-6 h-6 mr-1 tablette:inline" />
                  <FormattedMessage
                    id="post_form.publish_now"
                    defaultMessage="Publier maintenant"
                  />
                </div>
              </div>
            </button>
            <button type="button" onClick={() => changePublishNow(false)}>
              <div className="relative flex items-center my-2">
                <div
                  className={`relative border border-gray-300 rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none text-sm ${!publishNow
                    ? "text-brand_main border-brand_focus ring ring-brand_lighter ring-opacity-50"
                    : "text-gray-500 border-gray-300 "
                    }`}
                >
                  <ClockIcon className="hidden w-6 h-6 mr-1 tablette:inline" />
                  <FormattedMessage
                    id="post_form.schedule"
                    defaultMessage="Programmer"
                  />
                </div>
              </div>
            </button>
          </div>

          <FormField
            title={intl.formatMessage({
              id: "post_form.date",
              defaultMessage: "Date",
            })}
            hint={intl.formatMessage({
              id: "post_form.first_diffusion",
              defaultMessage: "Date de la première diffusion",
            })}
            error={errors?.post?.scheduled_at?.message}
            disabled={publishNow}
          >
            <Controller
              name="post.scheduled_at"
              control={control}
              rules={{
                // required: intl.formatMessage({
                //   id: "post_form.choose_diffusion_date",
                //   defaultMessage: "Vous devez choisir une date de diffusion.",
                // }),
                validate: (value) => {
                  if (!value && !publishNow) {
                    return intl.formatMessage({
                      id: "post_form.choose_diffusion_date",
                      defaultMessage:
                        "Vous devez choisir une date de diffusion.",
                    });
                  } else {
                    return true;
                  }
                },
              }}
              render={({ field: { onChange } }) => (
                <DatePicker
                  onChange={onChange}
                  error={errors?.post?.scheduled_at?.message}
                  options={{ minDate, maxDate }}
                  defaultValue={
                    post.scheduled_at
                      ? new Date(post.scheduled_at).toLocaleString("fr")
                      : undefined
                  }
                  disabled={publishNow}
                />
              )}
            />
          </FormField>
        </div>

        <FormField
          title={intl.formatMessage({
            id: "post_form.recurrency",
            defaultMessage: "Récurrence",
          })}
          hint={intl.formatMessage({
            id: "post_form.scheduling",
            defaultMessage:
              "La périodicité est calculée à partir de la date du premier post.",
          })}
          disabled={publishNow}
        >
          <Controller
            name="post.periodicity"
            control={control}
            rules={{
              required: intl.formatMessage({
                id: "error.required",
                defaultMessage: "Ce champ ne peut être vide.",
              }),
            }}
            render={({ field }) => (
              <Select
                onChange={(option) => field.onChange(option)}
                list={periodicityOptions}
                value={field.value}
                disabled={publishNow}
              />
            )}
          />
        </FormField>
        <div></div>
      </FormLayout>

      <div className="flex justify-end mb-16">
        <button
          disabled={submitted}
          className="relative disabled:bg-gray-300 btn-brand-primary"
          type="submit"
        >
          <Spinner
            className={`absolute ${!submitted && "invisible"
              } w-5 h-5 mb-1 ml-4 text-white animate-spin`}
          />
          <span className={` ${submitted && "invisible"}`}>
            <FormattedMessage
              id="post_form.save"
              defaultMessage="Enregistrer"
            />
          </span>
        </button>
      </div>
    </form>
  );

}
