import React from 'react'
import {
    FacebookLogo,
    TwitterLogo,
    LinkedinLogo,
    PinterestLogo,
    InstagramLogo,
    GoogleLogo
} from './channelIcons'
import { Channel, ChannelAccount } from '../types';

interface CharacterCountPostProps {
    twitterRemaining: number;
    instagramRemaining: number;
    facebookRemaining: number;
    googleMyBusinessRemaining: number;
    linkedInRemaining: number;
    pinterestRemaining: number;
    connectedChannels: Array<ChannelAccount & { channel: Channel }>;
}

const CharacterCountPost: React.FC<CharacterCountPostProps> = ({
    twitterRemaining,
    instagramRemaining,
    facebookRemaining,
    googleMyBusinessRemaining,
    linkedInRemaining,
    pinterestRemaining,
    connectedChannels
}) => {

    const isChannelConnected = (identifier: string) => {
        return connectedChannels.some(channel => channel.channel.identifier === identifier);
    }

    const getCharacterCountClass = (remaining: number) => {
        return remaining < 0 ? 'text-red-500' : 'text-gray-700';
    }

  return (
        <div className='flex items-center justify-start gap-4 mt-3 mb-3'>
            <div className='flex items-center justify-start gap-4 mt-3 mb-3'>
                {isChannelConnected("twitter") && (
                    <div className='flex items-center text-sm gap-2'>
                        <TwitterLogo className='h-4 w-4' />
                        <span className={getCharacterCountClass(twitterRemaining)}>{twitterRemaining}</span>
                    </div>
                )}
                {isChannelConnected("instagram") && (
                    <div className='flex items-center text-sm gap-2 fill-instagram'>
                        <InstagramLogo className='h-4 w-4' />
                        <span className={getCharacterCountClass(instagramRemaining)}>{instagramRemaining}</span>
                    </div>
                )}
                {isChannelConnected("facebook") && (
                    <div className='flex items-center text-sm gap-2 fill-facebook'>
                        <FacebookLogo className='h-4 w-4' />
                        <span className={getCharacterCountClass(facebookRemaining)}>{facebookRemaining}</span>
                    </div>
                )}
                {isChannelConnected("google_my_business") && (
                    <div className='flex items-center text-sm gap-2 fill-google'>
                        <GoogleLogo className='h-4 w-4' />
                        <span className={getCharacterCountClass(googleMyBusinessRemaining)}>{googleMyBusinessRemaining}</span>
                    </div>
                )}
                {isChannelConnected("linkedin") && (
                    <div className='flex items-center text-sm gap-2 fill-linkedin'>
                        <LinkedinLogo className='h-4 w-4' />
                        <span className={getCharacterCountClass(linkedInRemaining)}>{linkedInRemaining}</span>
                    </div>
                )}
                {isChannelConnected("pinterest") && (
                    <div className='flex items-center text-sm gap-2 fill-pinterest'>
                        <PinterestLogo className='h-4 w-4' />
                        <span className={getCharacterCountClass(pinterestRemaining)}>{pinterestRemaining}</span>
                    </div>
                )}
            </div>
            <div>
                <p className='text-sm text-[#818793]'>caractère restants</p>
            </div>
        </div>
    );
}

export default CharacterCountPost