import { CloudinaryFile } from "./cloudinary_custom_types";
import * as Turbo from "@hotwired/turbo";
declare global {
  interface Window {
    Turbo?: any;
  }
}
export type Company = {
  id?: number;
  first_name: string;
  last_name: string;
  company: string;
  website: string;
  email: string;
  phone: string;
  hashtag: string;
  displayed_name: string;
  gender: string;
  sendinblue_sender_name: string;
  sendinblue_sender_email: string;
  primary_color: string | null;
  profile_picture?: {
    id?: number,
    serialized_file: CloudinaryFile;
    crop_width: number;
    crop_height: number;
    crop_x: number;
    crop_y: number;
  } | null;
};

export type PostGenerator = {
  starting_at: string | null;
  channels: Channel[];
  periodicity: string;
  id?: number;
  channel_ids: number[];
  content: Content;
  publish_now: string;
};

export type Post = {
  // starting_at: string | null;
  //periodicity: string;
  id?: number;
  //channel_ids: number[];
  company_content: CompanyContent;
  //publish_now: string;
  periodicity: string;
  channel_accounts?: ChannelAccount[];
  channel_account_ids?: number[];
  scheduled_at: string | null;
  post_on_channels?: PostOnChannel[];
};


export type CompanyContent = {
  id?: number;
  body: Body;
};

export type ChannelAccount = {
  id?: number;
  channel_id?: number;
  channel?: Channel;
  picture_url?: string;
  displayed_name?: string;
}

export type Content = {
  focus: string;
  teaser: string;
  description: string;
  reconstructed_url: string;
  title: string;
  id?: number;
  cta: string | null;
  tag_ids: number[];
  // content_media: ContentMedium[];
  body: Body;
  expires_at: string | null;
};

export type Body = {
  id?: number;
  message: string;
  link_url: string | null;
  link_description: string | null;
  link_cta: string | null;
  body_media: MediaHolder[];
}

export function isPersisted<Model>(model: Model): model is Model & { id: number } {
  return "id" in model;
}


export type MediaHolder = {
  id?: number,
  destroy?: boolean,
  content_medium: ContentMedium,
  position?: number
}

export type ContentMedium = {
  id?: number;
  //position?: number;
  serialized_file: CloudinaryFile;
  //destroy?: boolean
};

export type Channel = {
  id: number;
  identifier: string;
  name?: string;
};

export type Tag = {
  id: string;
  label: string;
};

export interface TagSubGroup extends Tag {
  tags: Tag[];
}

export interface TagGroup extends Tag {
  tag_sub_groups: TagSubGroup[];
}

export type PostOnChannel = {
  id: number;
  channel_account_id: number;
}
