import StimulusDialog from "@stimulus-components/dialog"

export default class Dialog extends StimulusDialog {

  get as_modal() {
    return Boolean(this.data.get("as_modal") || false)
  }

  open() {
    console.log("as_modal()")

    console.log(this.as_modal)
    if (this.as_modal) {
      this.dialogTarget.showModal()
    } else {
      this.dialogTarget.show()
    }
  }

} 
