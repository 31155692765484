import React from "react"
import { FormattedMessage } from "react-intl";
import { SparklesIcon } from "@heroicons/react/24/solid";
import Spinner from "./Spinner";
import { placeholder } from "@cloudinary/react";

interface OpenAiFormProps {
  prompt: string,
  message: string,
  placeholder: string,
  setPrompt: React.Dispatch<React.SetStateAction<string>>
  loading: boolean
  error: string | null
  Submit: () => void
}


const OpenAiForm: React.FC<OpenAiFormProps> = ({
  prompt,
  message,
  placeholder,
  setPrompt,
  loading,
  error,
  Submit
}) => {

  return (
    <form>
      <div className="flex text-sm items-baseline font-semibold leading-6 text-gray-700">
        <SparklesIcon className="h-3 w-3 mr-1" />
        <FormattedMessage id="url_scrapper.prompt" defaultMessage="AI Generation" />
      </div>
      <div className="text-sm text-gray-500">
        <FormattedMessage id="url_scrapper.prompt" defaultMessage={message} />
      </div>
      <div className="flex items-center mt-3">
        <input
          type="text"
          onChange={
            (e) => setPrompt(e.target.value)
          }
          value={prompt}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              Submit();
            }
          }}
          className="pr-16 w-full text-sm form-input flex flex-1 rounded-md border-gray-300 shadow-sm focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20 "
          placeholder={placeholder}
        />
        <button
          type="button"
          onClick={Submit}
          className={`h-full w-16 p-2 font-medium text-white bg-brand_main rounded-r-md border border-gray-300 hover:bg-brand_darker duration-300 ${loading ? "ring-4 ring-brand_lighter" : ""} `}
        >
          <span className="mb-2">
            {!loading ? (
              <>
                <FormattedMessage id="url_scrapper.go" defaultMessage="GO!" />
                <SparklesIcon className="inline h-4 w-4" /></>) : <Spinner className="animate-spin mb-1 ml-4 h-5 w-5 text-white" />}
          </span>
        </button>
      </div>
      {
        error ?
          (
            <div className="mt-2 p-2 bg-red-500 text-white text-sm font-bold rounded">
              {error}
            </div>
          ) :
          (
            <></>
          )
      }
    </form>
  )
}

export default OpenAiForm