import React, { useState } from "react";
import { UseFormRegister } from "react-hook-form/dist/types";
import {
  GoogleLogo,
  FacebookLogo,
  LinkedinLogo,
  TikTokLogo,
  TwitterLogo,
  PinterestLogo,
  InstagramLogo,
} from "./channelIcons";
import { Popover } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Channel, ChannelAccount } from "../types";

type Props = {
  selected: Array<string>; // car l'input renvoie des strings de nombre
  channelAccounts: ChannelAccount[];
  register: UseFormRegister<any>;
  inputName: string;
};

function ChannelAccountSelector({ register, channelAccounts, inputName, selected }: Props) {
  const sortCA = (channelAccounts: ChannelAccount[]) => {
    // On prend les identifiants uniques des Channels Accounts
    let identifiers = [...new Set(channelAccounts.map(ca => (ca.channel as Channel).identifier))];

    let array: any[] = []
    // On range les ChannelAccounts par identifier
    identifiers.forEach(identifier => {
      const thisCA = {
        [identifier]: channelAccounts.filter(ca => (ca.channel as Channel).identifier === identifier),
      }
      array.push(thisCA)
    })
    // La fonction retourne les channels acocunts rangés par identifiant
    return array
  }
  const sortedChannelAccounts = sortCA(channelAccounts)
  return (
    <div className="flex flex-wrap my-2 gap-4">
      {channelAccounts.map(
        ({ id, picture_url, channel, displayed_name }) => {

          const identifier = channel?.identifier == "google_my_business" ? "google" : channel?.identifier
          return (
            <>
              {channelAccounts.length < 20 &&
                <>
                  <label
                    key={id}
                    className={`relative flex items-center h-10 w-10 min-w-[3rem] cursor-pointer group`}
                    title={displayed_name}
                  >
                    <img
                      className={` 
                    rounded-full object-cover h-10 w-10 outline border border-white outline-2 
                    ${selected.includes((id as Channel["id"]).toString()) ?
                          `outline-${identifier}` :
                          `opacity-50 outline-gray-300 group-hover:opacity-70 group-hover:outline-${identifier}`
                        } outline-offset-1
                  `}
                      width={100}
                      height={100}
                      src={picture_url}
                    />
                    <div className="absolute -bottom-1 -right-1 bg-white rounded-md p-0.5 border-gray-300 border">
                      <ChannelLogo
                        identifier={channel?.identifier as Channel["identifier"]}
                        active={selected.includes((id as Channel["id"]).toString())}
                      />
                    </div>

                    <input
                      {...register(inputName)}
                      className="hidden"
                      value={id}
                      type="checkbox"
                    />
                  </label>
                </>}
            </>
          );
        }

      )}
      {channelAccounts.length >= 20 &&
        <ChannelAccountSelectorDropdown
          sortedChannelAccounts={sortedChannelAccounts}
          selected={selected}
          inputName={inputName}
          register={register}
        />
      }
    </div>
  );
}

const ChannelLogo = ({
  identifier,
  active,
  ...props
}: {
  identifier: string;
  active: boolean;
  [key: string]: any;
}) => {
  switch (identifier) {
    case "google_my_business":
      return (
        <GoogleLogo
          className={`w-4 h-4 ${active
            ? "fill-google"
            : "fill-gray-400 opacity-60 group-hover:fill-google"
            }`}
        />
      );
    case "facebook":
      return (
        <FacebookLogo
          className={`w-4 h-4 ${active
            ? "fill-facebook"
            : "fill-gray-400 opacity-60 group-hover:fill-facebook"
            }`}
        />
      );
    case "linkedin":
      return (
        <LinkedinLogo
          className={`w-4 h-4 ${active
            ? "fill-linkedin"
            : "fill-gray-400 opacity-60 group-hover:fill-linkedin"
            }`}
        />
      );
    case "instagram":
      return (
        <InstagramLogo
          className={`w-4 h-4 ${active
            ? "fill-instagram"
            : "fill-gray-400 opacity-60 group-hover:fill-instagram"
            }`}
        />
      );
    case "pinterest":
      return (
        <PinterestLogo
          className={`w-4 h-4 ${active
            ? "fill-pinterest"
            : "fill-gray-400 opacity-60 group-hover:fill-pinterest"
            }`}
        />
      );
    case "tiktok":
      return (
        <TikTokLogo
          className={`w-4 h-4 ${active
            ? "fill-tiktok"
            : "fill-gray-400 opacity-60 group-hover:fill-tiktok"
            }`}
        />
      );
    case "twitter":
      return (
        <TwitterLogo
          className={`w-4 h-4 ${active
            ? "fill-twitter"
            : "fill-gray-400 opacity-60"
            }`}
        />
      );
    default:
      return (
        <FacebookLogo
          className={`w-4 h-4 ${active
            ? "fill-facebook"
            : "fill-gray-400 opacity-60 group-hover:fill-facebook"
            }`}
        />
      );
  }
};

// Composant

export function ChannelAccountSelectorDropdown({
  sortedChannelAccounts, selected, inputName, register
}: {
  sortedChannelAccounts: ChannelAccount[], selected: any, inputName: string, register: UseFormRegister<any>
}) {

  return (
    <Popover className="relative inline-block text-left">
      <div>
        <Popover.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Réseaux sociaux séléctionnés
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Popover.Button>
      </div>

      {/* Dropdown menu, show/hide based on dropdown state. */}
      <Popover.Panel
        transition
        className={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none w-60 overflow-y-auto max-h-96 gap-y-4`}
        unmount={false}
      >
        <div className="py-1">
          {sortedChannelAccounts.map((specificChannelAccount) => {
            return Object.entries(specificChannelAccount).map(([identifier, cas]) => {
              return (
                <>
                  <div className="flex items-center gap-3 p-1 border-b my-1 px-3 font-medium">
                    <ChannelLogo identifier={identifier} active={true} />
                    <p>{identifier}</p>
                  </div>
                  {(cas as unknown as ChannelAccount[]).map(({ displayed_name, id }) => {
                    return (
                      <div className="flex items-center gap-3 p-1">
                        <input
                          id={displayed_name}
                          type="checkbox"
                          defaultChecked={selected.includes(id?.toString())}
                          {...register(inputName)}
                          value={id}
                        >
                        </input>
                        <label htmlFor={displayed_name} className="line-clamp-1">

                          {displayed_name}
                        </label>
                      </div>
                    )
                  })
                  }
                </>
              )
            })
          })}
        </div>
      </Popover.Panel>
    </Popover>
  )
}

export default ChannelAccountSelector;
