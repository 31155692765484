import { TurboMount } from "turbo-mount";
import React from "react";
import { registerComponent } from "turbo-mount/react";

// i18n
import { IntlProvider } from "react-intl";
import fr from "../../config/locales/js-lang/fr.json";
import en from "../../config/locales/js-lang/en.json"; // on mettra le compile plus tard

// Composants React
import PostForm from './components/PostForm'

const turboMount = new TurboMount();

// to register a component use:
// registerComponent(turboMount, "Hello", Hello); // where Hello is the imported the component

// to override the default controller use:
// registerComponent(turboMount, "Hello", Hello, HelloController); // where HelloController is a Stimulus controller extended from TurboMountController

// If you want to automatically register components use:
// import { registerComponents } from "turbo-mount/registerComponents/react";
// const controllers = import.meta.glob("/controllers/**/*_controller.js", { eager: true });
// const components = import.meta.glob("/components/**/*.jsx", { eager: true });
// registerComponents({ turboMount, components, controllers });


// On wrap nos composants dans un HOC provider du context de traduction
const messages = { fr, en };

const translate = (component) => (props) => React.createElement(
	IntlProvider,
	{ locale: props.locale || "fr", messages: messages[props.locale] || fr },
	React.createElement(component, props)
)


registerComponent(turboMount, "PostForm", translate(PostForm));
// TODO: passer les autres composants 

