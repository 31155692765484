import { ContentMedium, MediaHolder } from '../types';
function assert(condition: boolean, message: string): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}

export function getCSRFToken() {
  const tag: HTMLElement | null = document.head.querySelector('[name=csrf-token]');
  console.log(tag)

  assert(tag instanceof HTMLMetaElement, "No'csrf-token' meta-tag found.");

  return tag.content;
}

// Used in content/post/pg forms
export function formatMedia(media: ContentMedium[]) {
  return media.map((m: ContentMedium, i: number) => {
    if (m.id) {
      return m.destroy
        ? { _destroy: true, id: m.id }
        : { position: i, id: m.id };
    } else {
      if (m.serialized_file.signature) {
        return {
          medium: `${m.serialized_file.identifier}#${m.serialized_file.signature}`,
          position: i,
        }
      } else {
        return {
          remoteMediumUrl: m.url,
          position: i,
        }
      }
    }
  });
}

export function formatMediaHolders(mediaHolders: MediaHolder[]) {
  return mediaHolders.map((m: MediaHolder, i: number) => {
    // si l'association existe déjà (donc si le cm existait aussi)
    if (m.id) {
      return m.destroy
        ? { _destroy: true, id: m.id } // On dégage l'association
        : { position: i, id: m.id, contentMediumId: m.content_medium.id }; // On garde l'association, et on a besoin du CM si jamais on duplique le body (on perdrai l'id du bm)
      // Dans tous les cas on ne touche pas CM lui-même
      // Si plus d'asso, on le supprimera par un job
    } else if (m.content_medium.id) {
      // Ici l'association n'existe pas mais le CM oui
      return m.destroy
        ? null // On dégage l'association
        : { position: i, contentMediumId: m.content_medium.id }; // On garde l'association
      // Dans tous les cas on ne touche pas CM lui-même
      // Si plus d'asso, on le supprimera par un job
    } else {
      if (m.content_medium.serialized_file.signature) {
        return {
          contentMediumAttributes: {
            medium: `${m.content_medium.serialized_file.identifier}#${m.content_medium.serialized_file.signature}`,
          },
          position: i
        }
      }
    }
  })
}



